<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { afterUpdate, onMount } from 'svelte';
  import { readable } from 'svelte/store';

  import AltPollTeaser from '$components/amedia/alt-poll-teaser/component/alt-poll-teaser.component.svelte';
  import Button from '$components/style/button/button.svelte';
  import Filter from '$components/common/filter/filter.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import ModalDialog from '$components/style/modal/modal-dialog.svelte';
  import PublicationRecommenderWrapper from '$components/common/site-recommender/site-recommender.wrapper.svelte';
  import SitesRecommenderTeaserWrapper from '$components/common/site-recommender-teaser/site-recommender-teaser.wrapper.svelte';
  import SitesSelector from '$components/common/sites-selector/sites-selector.svelte';
  import SubscriptionsWidget from '$components/common/widgets/subscriptions-widget.svelte';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import i18n from '$i18n/i18n.js';
  import { customClickEvent } from '$utils/adplogger.js';
  import { savePublicationPreferences } from '$utils/queries.js';
  import { canUseMotion } from '$stores/can-use-motion';
  import { fetchFrontFeedList } from '$stores/teaser-store';
  import type {
    DomainKeys,
    SitesDomains,
    SitesNames,
  } from '$stores/sites-store';
  import { dirtySelectedSites, user, type UserStore } from '$stores/user-store';
  import DiscoverTeaserWrapper from '$components/common/teaserlist/discover-teaser-wrapper.svelte';

  export let userData: Exclude<UserStore['data'], null>;
  export let location: string;
  export let primarySite: string;
  export let sitesDomains: SitesDomains;
  export let domainKeys: DomainKeys;
  export let sitesNames: SitesNames;
  export let innerWidth: number;

  const debug =
    new URL(window.location.href).searchParams.get('debug') === 'true';

  const resumeId = window.sessionStorage.getItem('altResumeId');

  const options = {
    debug,
    resumeId,
  };

  const { uuid, trackingKey } = userData;

  const ALL = 'alt';

  let filter: string;
  let teasers: Teaserlist['teasers'];
  let filterScrollList: HTMLElement | null = null;
  let dialogComponent: HTMLDialogElement;

  const headerStyle = css({
    margin: '$x6 auto $x4',
    paddingInline: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    '@media screen and (min-width: 768px)': {
      paddingInline: '2rem',
    },
  });

  const titleStyle = css({
    fontstyle: 'baseMetaM',
    fontFeatureSettings: "'ss02'",
    margin: '0',
    marginBlockEnd: '$x1',
    '& > span': {
      color: '$brandBaseBg',
    },
  });

  const pretitleStyle = css({
    fontstyle: 'baseHeadlineL',
    margin: '0',
  });

  const headerButtonStyle = css({
    marginTop: 'auto',
    '& .mozart-button': {
      '& > span': {
        padding: '$x2 $x3',
      },
    },
  });

  const errorStyle = css({
    margin: '$x6 $x4 $x20',
  });

  const verticalWrapperStyle = css({
    marginBottom: '$x10',
  });

  const displayContentStyle = css({
    display: 'contents',
  });

  const filterFeed = () => {
    if (filter !== ALL) {
      teasers.setFilter(filter);
      window.history.replaceState(null, '', `?filter=${filter}#${location}`);
    } else {
      window.history.replaceState(null, '', `?#${location}`);
      teasers.resetFilter();
    }

    window.scrollTo({
      top: 0,
      behavior: $canUseMotion ? 'smooth' : 'auto',
    });
  };

  const handleSelectedSitesClose = () => {
    dialogComponent.close();
  };

  const handleSelectedSites = async (e: CustomEvent) => {
    const { siteKey, checked } = e.detail;

    const event = customClickEvent(
      'mypublications-update',
      JSON.stringify({
        siteKey,
        preferred: checked,
      })
    );
    e.target?.dispatchEvent(event);

    await savePublicationPreferences({
      siteKey,
      preferred: checked,
    });

    filter = ALL;
    if (filterScrollList) filterScrollList.scrollLeft = 0;
  };

  const title = $i18n.t('frontpage.title.home');
  const pretitle = $i18n.t('frontpage.pretitle.home');

  let teaserListPromise = fetchFrontFeedList({
    trackingKey,
    uuid,
    location: 'frontpage',
  });

  $: filterOptions = $user.data?.preferredSites.reduce(
    (acc, { siteKey }) => ({ ...acc, [siteKey]: sitesNames[siteKey] }),
    {}
  );

  $: hasFilterOptions = Object.keys(filterOptions || {}).length > 1;

  $: {
    if ($dirtySelectedSites)
      teaserListPromise = fetchFrontFeedList({
        trackingKey,
        uuid,
        location: 'frontpage',
        forceRefresh: true,
      });
  }

  $: selectedSites =
    $user.data?.preferredSites.map(({ siteKey }) => siteKey) || [];

  $: showFilter = hasFilterOptions;

  $: siteSelectorOpen = false;

  let mainElement: HTMLElement | undefined;
  let adType: 'netboard' | 'midtbanner' | 'unknown' = 'unknown';

  afterUpdate(() => {
    if (mainElement) {
      adType = mainElement.clientWidth > 580 ? 'netboard' : 'midtbanner';
    }
  });

  const ratingIndex = 9;
  const pollIndex = 12;

  const getComponentByIndex = (index: number, innerWidth: number) => {
    switch (index) {
      case 3: {
        return DiscoverTeaserWrapper;
      }
      case 7: {
        return SitesRecommenderTeaserWrapper;
      }
      case ratingIndex: {
        return AltPollTeaser;
      }
      case pollIndex: {
        return AltPollTeaser;
      }
      case 12: {
        if (innerWidth < 768) return SubscriptionsWidget;
        break;
      }
    }
    // We need to type this as any else Typescript will complain about the missing $$props type in the markup below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  };

  const onUpdate = (ev: CustomEvent) => {
    handleSelectedSites(ev);
  };

  onMount(async () => {
    await teaserListPromise;
    const queryParams = new URLSearchParams(window.location.search);
    filter = queryParams.get('filter') || ALL;
  });
</script>

<ModalDialog
  bind:dialogComponent
  on:close={() => (siteSelectorOpen = false)}
  on:open={() => (siteSelectorOpen = true)}
>
  <SitesSelector
    visible={siteSelectorOpen}
    container={dialogComponent}
    on:close={handleSelectedSitesClose}
    on:update={handleSelectedSites}
  />
</ModalDialog>

<header class={headerStyle()}>
  <div>
    <h1 class={titleStyle()}>
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html title}
    </h1>
    <p class={pretitleStyle()}>{pretitle}</p>
  </div>

  <div class={headerButtonStyle()}>
    <Button
      text={$i18n.t('frontpage.buttons.changeSites')}
      size="none"
      type="signal"
      clickLabel="filter-feed"
      clickValue="add-more"
      id="add-more"
      on:click={() => {
        dialogComponent.showModal();
        siteSelectorOpen = true;
      }}
    />
  </div>
</header>

<PublicationRecommenderWrapper
  uuid={userData.uuid}
  {primarySite}
  {selectedSites}
  on:update={(ev) => {
    handleSelectedSites(ev);
  }}
/>

{#if showFilter}
  <Filter
    {filterOptions}
    {sitesDomains}
    {ALL}
    bind:filter
    bind:list={filterScrollList}
    on:filter={filterFeed}
  />
{/if}

<main
  bind:this={mainElement}
  class={verticalWrapperStyle()}
  id="main-content-begin"
>
  {#await teaserListPromise then list}
    {@const teaserList = readable(list.teasers)}
    <div
      itemscope
      itemtype="https://www.adplogger.no/json-schema/CustomElement"
      class={displayContentStyle()}
    >
      <meta
        itemprop="custom-element#data"
        content={JSON.stringify({ tracer_request_id: list.id })}
      />
      <meta itemprop="custom-element#name" content="ordino_front_feed" />
      <Teaserlist
        {teaserList}
        {options}
        {domainKeys}
        {adType}
        {primarySite}
        showNewSiteTeaser={true}
        bind:teasers
      >
        <svelte:fragment slot="after" let:index>
          <svelte:component
            this={getComponentByIndex(index, innerWidth)}
            {selectedSites}
            {primarySite}
            uuid={userData.uuid}
            on:update={onUpdate}
            parameters={{
              pollType: index === pollIndex ? 'poll' : 'rating',
              userData: userData,
              options: options,
              domainKeys: domainKeys,
              primarySite: primarySite,
              handleSelectedSites: handleSelectedSites,
            }}
          />
        </svelte:fragment>
      </Teaserlist>
    </div>
  {:catch error}
    <div class={errorStyle()}>
      <InfoBox title={$i18n.t('log.error.title')} hasLogo>
        <p slot="text">{error.message}</p>
      </InfoBox>
    </div>
  {/await}
</main>
