<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { css } from '@amedia/brick-tokens';

  import Button from '$components/style/button/button.svelte';
  import ModalDialog from '$components/style/modal/modal-dialog.svelte';
  import SitesSelector from '$components/common/sites-selector/sites-selector.svelte';
  import i18n from '$i18n/i18n.js';
  import type { PersonalizedPreferenceSitesArray } from '$stores/sites-store.js';

  export let sites: PersonalizedPreferenceSitesArray, selectedSites: string[];

  const listStyle = css({
    listStyle: 'none',
    padding: 0,
    margin: '$x4 0 ',

    display: 'flex',
    flexFlow: 'row wrap',
    gap: '$x2',
  });

  let dialogComponent: HTMLDialogElement;

  const dispatch = createEventDispatcher();

  const toggleModal = () => {
    dialogComponent.close();
  };

  $: sortedSites = selectedSites
    .map((siteKey) => {
      const name =
        sites.find((pub) => pub.siteKey === siteKey)?.name.full || '';
      return {
        siteKey,
        name,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  $: minSites = sortedSites.length < 2;

  $: visible = false;
</script>

<ModalDialog
  bind:dialogComponent
  on:open={() => (visible = true)}
  on:close={() => (visible = false)}
>
  <SitesSelector
    {visible}
    on:close={toggleModal}
    on:update
    container={dialogComponent}
  />
</ModalDialog>

<ul class={listStyle()}>
  {#each sortedSites as { siteKey, name } (siteKey)}
    <li>
      <Button
        disabled={minSites}
        clickLabel="mypublications-panel"
        clickValue="remove-site-{siteKey}"
        id="remove-site-{siteKey}"
        text={name}
        icon="add"
        rotateOnSelect={true}
        iconPosition="right"
        type="tertiary"
        selected
        on:click={() => {
          dispatch('update', { siteKey, checked: false });
        }}
      />
    </li>
  {/each}
</ul>
<Button
  on:click={() => dialogComponent.showModal()}
  text={$i18n.t('settings.publications.addPublications')}
  type="signal"
  clickLabel="mypublications-panel"
  clickValue="add-more"
  id="add-more"
/>
