<script lang="ts">
  import type { untoldPopularSchema } from '@amedia/beethoven-schemas';
  import type { z } from 'zod';
  import { css } from '@amedia/brick-tokens';
  import './brick-carousel.wrapper';

  import CarouselTeaser from '../carousel-teaser/carousel-teaser.svelte';

  import UntoldCard from './untold-card.svelte';

  import { UNTOLD_POPULAR, UNTOLD_LOGO } from '$utils/consts';
  import i18n from '$i18n/i18n';

  const untoldResponse: Promise<z.infer<typeof untoldPopularSchema>> = fetch(
    UNTOLD_POPULAR
  ).then((res) => res.json());

  const carouselStyleOverride = css({
    '[data-contents-wrapper]': {
      scrollPaddingInlineStart: '$x4',
      paddingInlineStart: '$x4 !important',
      paddingInlineEnd: '$x4 !important',
      '--brick--contentGap': '0px',
    },
  });
</script>

{#await untoldResponse then episodes}
  <CarouselTeaser
    class={carouselStyleOverride()}
    logoSrc={UNTOLD_LOGO}
    title="Untold"
    subtitle={$i18n.t('untold.tagline')}
    itemWidth="300px"
  >
    {#each episodes as { title, description, author, href, cover, duration }}
      <UntoldCard {title} {description} {author} {href} {cover} {duration} />
    {/each}
  </CarouselTeaser>
{/await}
