<script>
  export let mainColumnSpan = 1;
  export let firstColumnWidth = '1.8fr';
  export let dividers = false;
</script>

{#if $$slots.top}
  <div class="top">
    <slot name="top" />
  </div>
{/if}

<div
  class="column-wrapper"
  style:--main-colspan={$$slots.widgets ? mainColumnSpan : 2}
  style:--first-col-width={firstColumnWidth}
  style:--dividers={dividers ? '1px solid #e0e0e0' : 'none'}
>
  <div class="nav">
    <slot name="nav" />
  </div>

  <div class="main">
    <slot name="main" />
  </div>

  {#if $$slots.widgets}
    <div class="widgets">
      <slot name="widgets" />
    </div>
  {/if}
</div>

<style>
  .top:empty {
    display: none;
  }

  .widgets,
  .widgets:empty {
    display: none;
  }

  .column-wrapper {
    box-sizing: border-box;
    max-width: 1280px;
    margin-inline: auto;

    display: grid;
    grid-template-columns: var(--first-col-width) 5.3fr 2.9fr;
  }

  .nav {
    border-right: var(--dividers);
  }

  .main {
    grid-column: span var(--main-colspan);
  }

  .widgets {
    display: block;
    padding: var(--brick-space-x6);
    border-left: var(--dividers);
  }
</style>
