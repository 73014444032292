export function subscribeDailyPush(): Promise<Request> {
  let subscribeResponse: Promise<Request> = new Promise((resolve) =>
    resolve(new Request(''))
  );
  const tagId = '17-1-1172466';
  if (window.pasco) {
    subscribeResponse = window.pasco.subscribe(tagId);
  } else {
    console.error('window.pasco is undefined');
  }
  return subscribeResponse;
}

export function unsubscribeDailyPush() {
  let subscribeResponse: Promise<Request> = new Promise((resolve) =>
    resolve(new Request(''))
  );
  const tagId = '17-1-1172466';
  if (window.pasco) {
    subscribeResponse = window.pasco.unsubscribe(tagId);
  } else {
    console.error('window.pasco is undefined');
  }
  return subscribeResponse;
}
