export function dragScroll(node: HTMLElement) {
  node.style.cursor = 'grab';
  let pos = {
    left: 0,
    x: 0,
  };

  function mouseDown(e: MouseEvent) {
    node.style.cursor = 'grabbing';
    node.style.userSelect = 'none';
    node.classList.add('grabbed');

    document.body.style.cursor = 'grabbing';

    pos = {
      left: node.scrollLeft,
      x: e.clientX,
    };

    document.addEventListener('mouseup', mouseUp);
    document.addEventListener('mousemove', mouseMove);
  }

  function mouseMove(e: MouseEvent) {
    e.preventDefault();
    const dx = e.clientX - pos.x;
    node.scrollLeft = pos.left - dx;
    if (Math.abs(pos.left - dx) > 10) node.style.pointerEvents = 'none';
  }

  function mouseUp() {
    node.style.cursor = 'grab';
    node.classList.remove('grabbed');
    node.style.removeProperty('pointer-events');
    node.style.removeProperty('user-select');
    document.body.style.removeProperty('cursor');

    document.removeEventListener('mousemove', mouseMove);
    document.removeEventListener('mouseup', mouseUp);
  }

  node.addEventListener('mousedown', mouseDown);

  return {
    destroy() {
      node.removeEventListener('mousedown', mouseDown);
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('mousemove', mouseMove);
    },
  };
}
