<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { readable } from 'svelte/store';

  import type { DomainKeys } from '$stores/sites-store';
  import { type UserStore } from '$stores/user-store';
  import { fetchFrontFeedList } from '$stores/teaser-store';
  import i18n from '$i18n/i18n.js';
  import AltPollTeaser from '$components/amedia/alt-poll-teaser/component/alt-poll-teaser.component.svelte';
  import ESiteCarousel from '$components/common/e-site-carousel/e-site-carousel.svelte';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import UntoldCarousel from '$components/common/untold-carousel/untold-carousel.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import { Locations, Router } from '$stores/router-store';

  export let userData: Exclude<UserStore['data'], null>;
  export let domainKeys: DomainKeys;
  export let primarySite: string;

  const debug =
    new URL(window.location.href).searchParams.get('debug') === 'true';

  const resumeId = window.sessionStorage.getItem('altResumeId');

  const options = {
    debug,
    resumeId,
  };

  let teasers: Teaserlist['teasers'];

  const titleStyle = css({
    fontstyle: 'baseMetaM',
    fontFeatureSettings: "'ss02'",
    margin: '0',
    marginBlockEnd: '$x1',
    '& > span': {
      color: '$brandBaseBg',
    },
  });

  const headerStyle = css({
    margin: '$x6 auto $x4',
    paddingInline: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    '@media screen and (min-width: 768px)': {
      paddingInline: '2rem',
    },
  });

  const pretitleStyle = css({
    fontstyle: 'baseHeadlineL',
    margin: '0',
  });

  const errorStyle = css({
    margin: '$x6 $x4 $x20',
  });

  const verticalWrapperStyle = css({
    marginBottom: '$x10',
  });

  const displayContentStyle = css({
    display: 'contents',
  });

  const getComponentByIndex = (index: number, location: string) => {
    switch (index) {
      case 3: {
        if (location === Locations.Explore) return ESiteCarousel;
        break;
      }
      case 6: {
        if (location === Locations.Explore) return UntoldCarousel;
        return AltPollTeaser;
      }
      case 11: {
        return AltPollTeaser;
      }
    }
    // We need to type this as any else Typescript will complain about the missing $$props type in the markup below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  };

  $: teaserListPromise = fetchFrontFeedList({
    trackingKey: userData.trackingKey,
    uuid: userData.uuid,
    location: 'explore',
  });

  $: verticalWrapperWidth = 0;

  $: adType = verticalWrapperWidth > 580 ? 'netboard' : 'midtbanner';

  $: activeSection = $Router.path;
</script>

<header class={headerStyle()}>
  <div>
    <h1 class={titleStyle()}>{$i18n.t('frontpage.title.explore')}</h1>
    <p class={pretitleStyle()}>Populært</p>
  </div>
</header>

<main
  bind:clientWidth={verticalWrapperWidth}
  class={verticalWrapperStyle()}
  id="main-content-begin"
>
  {#await teaserListPromise then list}
    {@const teaserList = readable(list.teasers)}
    <div
      itemscope
      itemtype="https://www.adplogger.no/json-schema/CustomElement"
      class={displayContentStyle()}
    >
      <meta
        itemprop="custom-element#data"
        content={JSON.stringify({ tracer_request_id: list.id })}
      />
      <meta itemprop="custom-element#name" content="ordino_explore_feed" />
      <Teaserlist
        {teaserList}
        {options}
        {domainKeys}
        {adType}
        {primarySite}
        bind:teasers
      >
        <svelte:fragment slot="after" let:index>
          <svelte:component
            this={getComponentByIndex(index, activeSection)}
            {primarySite}
            uuid={userData.uuid}
            parameters={{
              pollType: index === 9 ? 'rating' : 'poll',
            }}
          />
        </svelte:fragment>
      </Teaserlist>
    </div>
  {:catch error}
    <div class={errorStyle()}>
      <InfoBox title={$i18n.t('log.error.title')} hasLogo>
        <p slot="text">{error.message}</p>
      </InfoBox>
    </div>
  {/await}
</main>

<style>
  :global(body) {
    overflow: scroll;
    scrollbar-gutter: stable;
  }
</style>
