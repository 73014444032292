<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import Button from '$components/style/button/button.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import type { ClientConfigSite } from '$stores/sites-store';
  import { user, UserState } from '$stores/user-store';
  import { customClickEvent } from '$utils/adplogger';
  import { savePublicationPreferences } from '$utils/queries';

  export let sites: ClientConfigSite[] = [];
  export let siteKey: string;
  export let showProfileLink = false;

  const site = sites.find(
    (site) => site.siteKey === siteKey
  ) as ClientConfigSite;

  const wrapperStyle = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '$x2',
  });

  const topWrapperStyle = css({
    display: 'flex',
    alignItems: 'center',
    gap: '$x2',
  });

  const imgStyle = css({
    width: '100%',
    height: 'auto',
    maxWidth: '40px',
    borderRadius: '50%',
    display: 'block',
    marginRight: 'auto',
  });

  const headlineStyle = css({
    fontstyle: 'baseHeadlineL',
    margin: '0',
  });

  const metaStyle = css({
    fontstyle: 'baseMetaM',
    margin: '0',
  });

  const linkListStyle = css({
    display: 'flex',
    gap: '$x1',
    margin: '$x1 0',
    padding: 0,
    listStyle: 'none',
  });

  const contentStyle = css({
    fontstyle: 'baseBodyS',
    display: 'block',
    margin: '$x2 0 0',
  });

  $: preferredSite = !!$user.data?.preferredSites.find(
    (publication) => publication.siteKey === siteKey
  );
  $: numPreferredSites = $user.data?.preferredSites.length || 1;
</script>

<InfoBox backgroundColor="$baseBg">
  <aside class={wrapperStyle()} slot="text">
    <div class={topWrapperStyle()}>
      <img
        height="40"
        class={imgStyle()}
        src={site.logo.square}
        alt="{site.name.full} logo"
      />
      {#if showProfileLink}
        <Button
          clickLabel="site-profile-link"
          clickValue={siteKey}
          text="Profil"
          icon="link-internal"
          href="?sitekey={siteKey}#site-profile"
        />
      {/if}
      {#if $user.state > UserState.HASACCESS}
        <Button
          disabled={numPreferredSites < 2 && preferredSite}
          selected={preferredSite}
          clickLabel="site-profile-follow"
          clickValue={siteKey}
          text={preferredSite ? 'Følger' : 'Følg'}
          icon="add"
          rotateOnSelect
          iconPosition={preferredSite ? 'right' : 'left'}
          on:click={(ev) => {
            preferredSite = !preferredSite;
            const event = customClickEvent(
              'mypublications-update',
              JSON.stringify({
                siteKey,
                preferred: preferredSite,
              })
            );
            ev.target?.dispatchEvent(event);

            savePublicationPreferences({
              siteKey,
              preferred: preferredSite,
            });
          }}
        />
      {/if}
    </div>
    <p class={headlineStyle()}><strong>{site.name.full}</strong></p>
    {#if site.location}
      <p class={metaStyle()}>
        {site.location.county}, {site.location.municipality}
      </p>
    {/if}
    <ul class={linkListStyle()}>
      {#each site.links || [] as { id, href, text }}
        <li>
          <Button
            clickLabel="site-profile-link"
            clickValue={id}
            size="small"
            icon="link-external"
            {text}
            {href}
          />
        </li>
      {/each}
    </ul>
    {#if site.description}
      <p class={contentStyle()}>{site.description}</p>
    {/if}
  </aside>
</InfoBox>
