<script lang="ts">
  import Button from '$components/style/button/button.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import i18n from '$i18n/i18n.js';
</script>

<main>
  <InfoBox title="Hei!">
    <svelte:fragment slot="text">
      <p>{$i18n.t('log.info.personalized.text')}</p>
    </svelte:fragment>
    <Button
      href="https://www.aid.no/aid/personvern/tilpasset_innhold?requestedUrl=https%3A%2F%2Fwww.alt.no%2F"
      type="secondary"
      text={$i18n.t('log.info.personalized.aidButton')}
      slot="button"
      clickLabel="myalt"
      clickValue="aid-profil"
      id="aid-profil"
    />
  </InfoBox>
</main>

<style>
  main {
    margin-top: 100px;
    padding-inline: 16px;
  }
</style>
