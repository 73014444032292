<script lang="ts">
  import LeftNav from './left-nav.svelte';
  import BottomNav from './bottom-nav.svelte';
  import TopNav from './top-nav.svelte';

  import Toast from '$components/style/toast/toast.svelte';
  import { Locations } from '$stores/router-store';

  export let hasAccess = true;
  export let position: 'top' | 'bottom' | 'left' = 'bottom';
  export let showLinks = false;
  export let location: Locations = Locations.Home;
  export let motherLink: string;
  export let motherLogo: string;
  export let motherLinkLabel: string;
  export let showOnboardingHelper = false;
  export let newUser = false;
</script>

<!-- This component exists on every ALT page so we put our Toast singleton here -->
<Toast />

{#if position === 'left' && hasAccess}
  <LeftNav
    {location}
    {motherLogo}
    {motherLink}
    {motherLinkLabel}
    {showLinks}
    {showOnboardingHelper}
    on:change
  />
{:else}
  <TopNav
    showInfoPageLinks={!hasAccess}
    logoType={newUser ? 'logo-alt-word' : 'logo-alt'}
    {location}
    {showLinks}
    {motherLink}
    {motherLogo}
    on:change={(event) => {
      document.location = '/?#' + event.detail.location;
    }}
  />
  {#if hasAccess}
    <BottomNav {location} on:change />
  {/if}
{/if}
