import {
  differenceInMinutes,
  differenceInSeconds,
  differenceInCalendarDays,
} from 'date-fns';

export function getHumanReadableDate(
  comparisonDate: string | Date | undefined,
  excludeHour?: boolean
): string | null {
  const relativeTimeFormat = (
    value: number,
    unit: Intl.RelativeTimeFormatUnit
  ) =>
    new Intl.RelativeTimeFormat('no', { numeric: 'auto' }).format(value, unit);

  if (!comparisonDate) return null;

  const now = new Date();
  const date = new Date(comparisonDate);

  const diffSeconds = differenceInSeconds(date, now);
  if (Math.abs(diffSeconds) < 60)
    return relativeTimeFormat(diffSeconds, 'second');

  const diffMinutes = differenceInMinutes(date, now);
  if (Math.abs(diffMinutes) < 60)
    return relativeTimeFormat(diffMinutes, 'minute');

  const hourMinutes = date.toLocaleTimeString('no', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const diffDays = differenceInCalendarDays(date, now);
  const withinOneDay = Math.abs(diffDays) <= 1;

  if (withinOneDay)
    return `${relativeTimeFormat(diffDays, 'day')} kl. ${hourMinutes}`;

  const locale = new Intl.DateTimeFormat('no', {
    month: 'long',
    day: 'numeric',
  });

  return `${locale.format(date)}${!excludeHour ? ` kl. ${hourMinutes}` : ''}`;
}
