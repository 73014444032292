export enum ToggleStatus {
  On = 'on',
  Off = 'off',
}

export function saveOverallPushStatus(toggleStatus: ToggleStatus) {
  localStorage.setItem('mozart:settings:overall-push', toggleStatus);
}

export function getOverallPushStatus(): ToggleStatus {
  const status = localStorage.getItem('mozart:settings:overall-push');
  console.log('status', status);
  return status === ToggleStatus.On ? ToggleStatus.On : ToggleStatus.Off;
}

export function saveDailyPushStatus(toggleStatus: ToggleStatus) {
  localStorage.setItem('mozart:settings:daily-push', toggleStatus);
}

export function getDailyPushStatus(): ToggleStatus {
  const status = localStorage.getItem('mozart:settings:daily-push');
  return status === ToggleStatus.On ? ToggleStatus.On : ToggleStatus.Off;
}

export const persistPWA = () => {
  const params = new URLSearchParams(location.search);
  const isPWA = params.get('src') === 'pwa';
  if (isPWA) {
    localStorage.setItem('pwa', 'true');
    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    );
  }
};

export function isPWA(): boolean {
  return (
    localStorage.getItem('pwa') === 'true' ||
    localStorage.getItem('testpush') === 'true'
  );
}
