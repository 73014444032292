<script>
  import { css } from '@amedia/brick-tokens';

  import Button from '$components/style/button/button.svelte';

  /** Scroll-container element ref */
  export let scrollList;
  export let scrollPosition = 0;
  export let id;
  export let gridArea;

  const scrollContainerStyle = css({
    gridArea: gridArea,
    height: '100%',
    padding: '$x2',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: '"left right"',
    alignItems: 'center',
    pointerEvents: 'none',

    '& > :where(button-left, button-right)': {
      gridArea: 'right',
      translate: '1.5rem 0',
      justifySelf: 'end',
      pointerEvents: 'auto',
      filter: 'drop-shadow(0 1px 16px rgba(0, 0, 0, 0.15))',
    },

    '& > button-left': {
      gridArea: 'left',
      translate: '-1.5rem 0',
      justifySelf: 'start',
    },

    '.mozart-button': {
      height: '32px',
      width: '32px',
    },
  });

  let isHolding = null;

  const handleClickLeft = (_, amount = 200) => {
    scrollList.scrollBy(-amount, 0);
    if (scrollList.scrollLeft <= amount) scrollList.scrollLeft = 0;
  };

  const handleClickRight = (_, amount = 200) => {
    scrollList.scrollBy(amount, 0);
    if (
      scrollList.scrollLeft + amount >=
      scrollList.scrollWidth - scrollList.clientWidth
    )
      scrollList.scrollLeft = scrollList.scrollWidth;
  };

  const handleMouseDown = (e) => {
    const left = e.detail?.id.includes('left');

    isHolding = setTimeout(() => {
      if (left) handleClickLeft(0, 10000);
      else handleClickRight(0, 10000);
    }, 1000);
  };

  $: isScrollMax =
    scrollPosition + 100 >= scrollList?.scrollWidth - scrollList?.clientWidth;
  $: isScrollMin = scrollPosition <= 100;
</script>

<svelte:window
  on:mouseup={() => {
    if (isHolding) clearTimeout(isHolding);
  }}
/>

<scroll-button-container class={scrollContainerStyle()} {id}>
  {#if !isScrollMin}
    <button-left>
      <Button
        type="round"
        size="none"
        icon="arrow-left"
        clickLabel="filter-feed"
        clickValue="{id}-left"
        id="{id}-left"
        on:click={handleClickLeft}
        on:mousedown={handleMouseDown}
      />
    </button-left>
  {/if}
  {#if !isScrollMax}
    <button-right>
      <Button
        type="round"
        size="none"
        icon="arrow-right"
        clickLabel="filter-feed"
        clickValue="{id}-right"
        id="{id}-right"
        on:click={handleClickRight}
        on:mousedown={handleMouseDown}
      />
    </button-right>
  {/if}
</scroll-button-container>
