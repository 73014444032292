<script lang="ts">
  import SitesRecommenderTeaser from './site-recommender-teaser.svelte';

  import { personalizedSitesList } from '$stores/sites-store.js';

  export let selectedSites: string[] = [];
  export let uuid: string | null;
  export let primarySite: string;

  const dismissedTeaser = Boolean(
    window.localStorage.getItem('dismissedSitesRecommenderTeaser')
  );

  function dismissTeaser() {
    window.localStorage.setItem('dismissedSitesRecommenderTeaser', 'true');
    showTeaser = false;
  }

  $: showTeaser = !dismissedTeaser && Math.random() > 0.8;
</script>

{#if showTeaser}
  {#await personalizedSitesList(uuid || '') then sites}
    {@const suggestedPublications = sites.filter(
      (site) =>
        site.siteKey !== primarySite &&
        !!site.rank &&
        !selectedSites?.includes(site.siteKey)
    )}
    {#if suggestedPublications.length > 0}
      <SitesRecommenderTeaser
        site={suggestedPublications[0]}
        on:update
        on:dismiss={dismissTeaser}
      />
    {/if}
  {/await}
{/if}
